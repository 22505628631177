<template>
  <div class="medal-page">
    <div class="medal-card">
      <div class="title">规则与奖励：</div>
      <div class="content content-text">
        <p>1.记账天数是指您真实使用记账功能(文字、语音记账皆可)的天数。例如过去7天未记账，今天补记7天的账，只算1天。</p>
        <p>2.本规则只适用于记账天数勋章的获得，不适用于其他地方记账天数的计算。</p>
        <p>3.当用户获得勋章时，勋章会展示在我的页面个人头像旁，同时解锁勋章对应的神秘奖励。</p>
      </div>
    </div>
    <div class="medal-card">
      <div class="title">记账天数勋章达成要求：</div>
      <div class="content">
        <img
        src="//download-cos.yofish.com/yofish-gongjushiyebu/20221208091933288-medal-bg1.png" alt="">
      </div>
    </div>
    <div class="medal-card">
      <div class="title">记账笔数达成要求：</div>
      <div class="content">
        <img
      src="///download-cos.yofish.com/yofish-gongjushiyebu/20221208092142832-medal-bg2.png" alt="">
      </div>
    </div>
    <div class="medal-card">
      <div class="title">特殊成就达成要求：</div>
      <div class="content">
        <img
        src="//download-cos.yofish.com/yofish-gongjushiyebu/2022120809222871-medal-bg3.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Medel',
  mounted() {
    document.title = '规则与奖励';
  },
};
</script>
<style lang="scss" scoped>
.medal-page {
  padding-top: 46px;
  font-family: PingFangSC-Medium, PingFang SC;
  background: #F6F6F6;
  padding-bottom: 98px;
}
.medal-card {
  // margin: 0 26px;
  margin-bottom: 80px;
  .title {
    font-size: 32px;
    font-weight: bold;
    color: #333333;
    line-height: 45px;
    margin-left: 26px;
    margin-right: 26px;
  }
  .content {
    margin-top: 42px;
    &.content-text {
      margin-left: 26px;
      margin-right: 26px;
    }
    img {
      max-width: 100%;
    }
    p {
      font-size: 28px;
      font-weight: 400;
      color: #333333;
      line-height: 50px;
      margin-bottom: 44px;
    }
  }
}
</style>
